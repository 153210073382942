const MOBILE_VIEW = "@media (max-width: 750px)"

const fontsChoose = "#23374d #F3950D #B61919 #EEEEEE".split(" ")

export const pageSetting = {
  text: {
    fontFamily: "Nunito Seans",
    color: fontsChoose[0],
  },
  text2: {
    fontFamily: "Nunito Seans",
    color: "#000",
  },
  textLight: {
    fontFamily: "Nunito Seans",
    color: "#fff",
  },
  secondary: {
    color: fontsChoose[1],
  },
  btn: {
    // not used
  },
  footer: fontsChoose[0],
  attention: fontsChoose[2],
  gray: fontsChoose[3],
}

const palette = {
  theme_navy: "#23374d",
  theme_blue: "#1089ff",
  theme_cold: "#e5e5e5",
  theme_retro: "#fcfcfc",

  white: "#fff",
  offWhite: "#f0f0f0",
  lightGrey: "#e6e6e6",
  softGrey: "#cccccc",
  grey: "#808080",
  darkGrey: "#4d4d4d",
  almostBlack: "#222",
  black: "#000",

  lightYellow: "#feffc6",
  green: "#71abaa",
  blue: "#94cdff",
  lightBlue: "#bde0ff",
}

const gutterBase = 4
const radiusBase = 2
const theme = {
  gutterSmall: gutterBase,
  gutter: gutterBase * 2,
  gutterMedium: gutterBase * 3,
  gutterBig: gutterBase * 4,
  radius: radiusBase,
  radiusBig: radiusBase * 3,
}

export { MOBILE_VIEW, palette, theme }
