import React, { useState } from "react"

import Link from "gatsby-link"

import {
  AppBar,
  Box,
  Button,
  IconButton,
  Menu,
  MenuItem,
  Typography,
} from "@mui/material"
import MenuIcon from "@mui/icons-material/Menu"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

import { palette, pageSetting } from "../helpers/theme"
import { capitalFirstLetter, ofert } from "../helpers/common"

const pages = [
  { name: "Start", link: "/" },
  { name: "O nas", link: "/o-nas/" },
  { name: "Dlaczego My", link: "/dlaczego-my/" },
  { name: "Oferta", link: null },
  { name: "Cennik", link: "/cennik/" },
  { name: "Kontakt", link: "/kontakt/" },
]

const DesctopMenu = ({ handleOpenOfert, handleCloseNavMenu, styles, size }) => (
  <Box sx={styles.desctopRoot(size)}>
    {pages.map(page =>
      page.link !== null ? (
        <Link css={styles.link} to={page.link} key={page.name}>
          <Button
            id={page.name === "Oferta" ? "ofert" : page.name}
            sx={styles.desctopBtn(pageSetting.text)}
            onClick={
              page.name === "Oferta" ? handleOpenOfert : handleCloseNavMenu
            }
          >
            {page.name}
          </Button>
        </Link>
      ) : (
        <Button
          key={page.name}
          sx={styles.desctopBtn(pageSetting.text)}
          id={page.name === "Oferta" ? "ofert" : page.name}
          onClick={
            page.name === "Oferta" ? handleOpenOfert : handleCloseNavMenu
          }
        >
          {page.name}
        </Button>
      )
    )}
  </Box>
)

const MobileMenu = ({
  styles,
  anchorElNav,
  handleOpenOfert,
  handleCloseNavMenu,
  handleOpenNavMenu,
  pages,
}) => (
  <div css={styles.root}>
    <Box sx={{ display: { sm: "none" }, textAlign: "end" }}>
      <IconButton
        aria-controls="menu-appbar"
        aria-haspopup="true"
        onClick={handleOpenNavMenu}
        color="primary"
      >
        <MenuIcon css={{ fontSize: 40 }} />
      </IconButton>
      <Menu
        id="menu-appbar"
        anchorEl={anchorElNav}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
        transformOrigin={{
          vertical: "top",
          horizontal: "left",
        }}
        open={Boolean(anchorElNav)}
        onClose={handleCloseNavMenu}
        sx={{
          display: { xs: "block", md: "none" },
        }}
      >
        {pages.map(page => (
          <MenuItem
            key={page.name}
            id={page.name === "Oferta" ? "ofert" : page.name}
            onClick={
              page.name === "Oferta" ? handleOpenOfert : handleCloseNavMenu
            }
          >
            {page.link !== null ? (
              <Link css={styles.link} to={page.link}>
                <Typography textAlign="center" css={pageSetting.text}>
                  {page.name}
                </Typography>
              </Link>
            ) : (
              <Typography textAlign="center" css={pageSetting.text}>
                {page.name}
              </Typography>
            )}
          </MenuItem>
        ))}
      </Menu>
    </Box>
  </div>
)

const CustomMenu = () => {
  const theme = useTheme()
  const matchesLG = useMediaQuery(theme.breakpoints.up("lg"))
  const [anchorElNav, setAnchorElNav] = useState(null)
  const [anchorElOfert, setAnchorElOfert] = useState(null)

  const handleOpenNavMenu = event => setAnchorElNav(event.currentTarget)

  const handleOpenOfert = event => setAnchorElOfert(event.currentTarget)

  const handleCloseNavMenu = () => setAnchorElNav(null)

  const handleCloseOfert = () => {
    setAnchorElOfert(null)
    setAnchorElNav(null)
  }

  return (
    <AppBar position="static" css={{ background: "none" }}>
      <MobileMenu
        styles={styles}
        anchorElNav={anchorElNav}
        handleCloseNavMenu={handleCloseNavMenu}
        handleOpenNavMenu={handleOpenNavMenu}
        handleOpenOfert={handleOpenOfert}
        pages={pages}
      />
      <DesctopMenu
        handleOpenOfert={handleOpenOfert}
        handleCloseNavMenu={handleCloseNavMenu}
        styles={styles}
        size={matchesLG}
      />
      <Menu
        id="ofert"
        open={Boolean(anchorElOfert)}
        onClose={handleCloseOfert}
        anchorEl={anchorElOfert}
        anchorOrigin={{
          vertical: "bottom",
          horizontal: "left",
        }}
        keepMounted
      >
        {ofert.map(page => (
          <Link key={page.name} to={page.link} css={styles.link}>
            <MenuItem onClick={handleCloseOfert}>
              <Typography textAlign="center" css={pageSetting.text}>
                {capitalFirstLetter(page.name)}
              </Typography>
            </MenuItem>
          </Link>
        ))}
      </Menu>
    </AppBar>
  )
}

const styles = {
  root: {
    width: "100%",
  },
  desctopBtn: set => ({
    my: 1,
    color: palette.theme_navy,
    display: "block",
    fontSize: 18,
    fontWeight: 500,
    ...set,
  }),
  desctopRoot: size => ({
    flexGrow: 1,
    justifyContent: "space-between",
    display: { xs: "none", sm: "flex" },
    width: size ? 1200 : null,
    margin: size ? "auto" : null,
    paddingLeft: 1,
  }),
  activeLink: {
    background: palette.theme_blue,
    color: "black",
  },
  link: {
    textDecoration: "none",
    color: "inherit",
    '@media (max-width: 600px)': {
      width: "100%",
      display: "flex",
      justifyContent: "flex-start",
    },
  },
}

export default CustomMenu
