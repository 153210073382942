import * as React from "react"

import { pageSetting, palette, theme } from "../helpers/theme"
import { MailIcon, PhoneIcon } from "../helpers/Icons"
import Logo from "../images/procalculo.png"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"
import { Link } from "gatsby"
import { Button } from "@mui/material"

const ContactBox = ({ match }) => (
  <div css={styles.contactBox(match)}>
    <Button
      sx={styles.btn(pageSetting.attention)}
      href="tel:517492682"
      startIcon={<PhoneIcon />}
    >
      517 492 682
    </Button>
    <Button
      sx={styles.btn(pageSetting.attention)}
      href="tel:183000382"
      startIcon={<PhoneIcon />}
    >
      (18) 3000 382
    </Button>
    <Button
      sx={styles.btn(palette.black)}
      size="small"
      href="mailto:procalculo.biuro@gmail.com"
      startIcon={<MailIcon />}
    >
      procalculo.biuro@gmail.com
    </Button>
  </div>
)

const Header = ({ siteTitle }) => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))
  const aboveLG = useMediaQuery(theme.breakpoints.up("lg"))

  return (
    <div position="static" css={styles.root}>
      <div css={styles.layout(aboveLG)}>
        <Link to="/">
          <img src={Logo} css={styles.logo} alt="" />
        </Link>
        <ContactBox match={matches} />
      </div>
    </div>
  )
}
const styles = {
  root: {
    background: palette.theme_cold,
    padding: theme.gutterBig,
  },
  btn: color => ({
    alignSelf: "center",
    textTransform: "lowercase",
    color: color,
    padding: 0,
  }),
  layout: size => ({
    display: "flex",
    justifyContent: "space-between",
    alignItems: "center",
    maxWidth: 1200,
    margin: "auto",
    paddingLeft: size && 8,
    paddingRight: size && 8,
  }),
  logo: {
    width: 200,
    margin: 0,
  },
  phoneBox: {
    display: "flex",
    alignItems: "center",
    margin: 0,
    padding: 0,
  },
  contactBox: match => ({
    display: match ? "flex" : "none",
    flexDirection: "column",
  }),
}
export default Header
