import * as React from "react"
import Footer from "./Footer"
import { theme } from "../helpers/theme"
// import { useStaticQuery, graphql } from "gatsby"

import Header from "./header"
import CustomMenu from "./CustomMenu"
import "./layout.css"

const Layout = ({ children }) => {
  // const data = useStaticQuery(graphql`
  //   query SiteTitleQuery {
  //     site {
  //       siteMetadata {
  //         title
  //       }
  //     }
  //   }
  // `)

  /* <Header siteTitle={data.site.siteMetadata?.title || `Title`} /> */
  return (
    <div css={styles.root}>
      <Header />
      <CustomMenu />
      <div css={styles.insideFrame}>{children}</div>
      <Footer />
    </div>
  )
}

const styles = {
  root: {
    display: "flex",
    flexDirection: "column",
    minHeight: "100vh",
  },
  insideFrame: {
    maxWidth: 1200,
    margin: "auto",
    width: "100%",
    padding: theme.gutterBig,
    paddingBottom: theme.gutterBig * 4,
    textAlign: "center",
    flexGrow: 1,
  },
}

export default Layout
