import * as React from "react"
import PhoneIcon from "@mui/icons-material/Phone"
import MailIcon from "@mui/icons-material/Mail"
import SupportAgentIcon from "@mui/icons-material/SupportAgent"
import SchoolIcon from "@mui/icons-material/School"
import AutorenewIcon from "@mui/icons-material/Autorenew"
import AccessibilityNewIcon from "@mui/icons-material/AccessibilityNew"
import AttachMoneyIcon from "@mui/icons-material/AttachMoney"
import PointOfSaleIcon from "@mui/icons-material/PointOfSale"
import PriceCheckIcon from "@mui/icons-material/PriceCheck"
import CardGiftcardIcon from "@mui/icons-material/CardGiftcard"
import AccountBalanceIcon from "@mui/icons-material/AccountBalance"
import TrendingUpIcon from "@mui/icons-material/TrendingUp"
import CheckCircleOutlineIcon from "@mui/icons-material/CheckCircleOutline"

const PeopleIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-people-fill"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M7 14s-1 0-1-1 1-4 5-4 5 3 5 4-1 1-1 1H7zm4-6a3 3 0 1 0 0-6 3 3 0 0 0 0 6z" />
    <path
      fillRule="evenodd"
      d="M5.216 14A2.238 2.238 0 0 1 5 13c0-1.355.68-2.75 1.936-3.72A6.325 6.325 0 0 0 5 9c-4 0-5 3-5 4s1 1 1 1h4.216z"
    />
    <path d="M4.5 8a2.5 2.5 0 1 0 0-5 2.5 2.5 0 0 0 0 5z" />
  </svg>
)

const BankIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-bank"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M8 .95 14.61 4h.89a.5.5 0 0 1 .5.5v2a.5.5 0 0 1-.5.5H15v7a.5.5 0 0 1 .485.379l.5 2A.5.5 0 0 1 15.5 17H.5a.5.5 0 0 1-.485-.621l.5-2A.5.5 0 0 1 1 14V7H.5a.5.5 0 0 1-.5-.5v-2A.5.5 0 0 1 .5 4h.89L8 .95zM3.776 4h8.447L8 2.05 3.776 4zM2 7v7h1V7H2zm2 0v7h2.5V7H4zm3.5 0v7h1V7h-1zm2 0v7H12V7H9.5zM13 7v7h1V7h-1zm2-1V5H1v1h14zm-.39 9H1.39l-.25 1h13.72l-.25-1z" />
  </svg>
)

const CheckIcon = props => (
  <svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    fill="currentColor"
    className="bi bi-check2-square"
    viewBox="0 0 16 16"
    {...props}
  >
    <path d="M3 14.5A1.5 1.5 0 0 1 1.5 13V3A1.5 1.5 0 0 1 3 1.5h8a.5.5 0 0 1 0 1H3a.5.5 0 0 0-.5.5v10a.5.5 0 0 0 .5.5h10a.5.5 0 0 0 .5-.5V8a.5.5 0 0 1 1 0v5a1.5 1.5 0 0 1-1.5 1.5H3z" />
    <path d="m8.354 10.354 7-7a.5.5 0 0 0-.708-.708L8 9.293 5.354 6.646a.5.5 0 1 0-.708.708l3 3a.5.5 0 0 0 .708 0z" />
  </svg>
)

export {
  AccessibilityNewIcon,
  AccountBalanceIcon,
  AttachMoneyIcon,
  AutorenewIcon,
  BankIcon,
  CardGiftcardIcon,
  CheckIcon,
  MailIcon,
  PeopleIcon,
  PhoneIcon,
  PointOfSaleIcon,
  PriceCheckIcon,
  SchoolIcon,
  SupportAgentIcon,
  TrendingUpIcon,
  CheckCircleOutlineIcon,
}
