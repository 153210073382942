import { Typography } from "@mui/material"
import React from "react"
import { pageSetting, palette, theme } from "../helpers/theme"
import { useTheme } from "@mui/material/styles"
import useMediaQuery from "@mui/material/useMediaQuery"

const Footer = () => {
  const theme = useTheme()
  const matches = useMediaQuery(theme.breakpoints.up("sm"))

  return (
    <div css={styles.root(matches)}>
      <div css={styles.textFooter}>
        <Typography variant="caption">
          Tel: (18) 3000 382 - Księgowość
        </Typography>
        <Typography variant="caption">Tel: 517 492 682 - Księgowość</Typography>
        <Typography variant="caption">
          Tel: 731 793 193 - Ubezpieczenia
        </Typography>
        <Typography variant="caption">
          {" "}
          e-mail: procalculo.biuro@gmail.com{" "}
        </Typography>
        <Typography variant="caption">e-mail: procalculo@onet.pl</Typography>
      </div>
      <div css={styles.textFooter}>
        <Typography variant="caption">
          Biuro Rachunkowe Pro Calculo w Grybowie
        </Typography>
        <Typography variant="caption">ul. Grunwaldzka 23</Typography>
        <Typography variant="caption">Czynne pn-pt 8:00 - 15:00</Typography>
      </div>
    </div>
  )
}

const styles = {
  root: match => ({
    display: "flex",
    background: pageSetting.footer,
    height: "fit-content",
    padding: theme.gutter,
    justifyContent: match ? "space-evenly" : "flex-start",
    flexWrap: "wrap",
  }),
  textFooter: {
    display: "flex",
    color: palette.offWhite,
    flexDirection: "column",
    justifyContent: "center",
    padding: theme.gutter,
  },
}

export default Footer
